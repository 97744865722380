<template>
    <v-container>
        <v-row class="ma-10" justify="center" align="center">
            <v-col cols="12" md="6">
                <v-img width="80%" src="../assets/logo.png"></v-img>
            </v-col>
            <v-col cols="12" md="6">
                <div class="headline primary--text font-weight-bold">About Grouptrip</div>
                <p class="mt-5 text-h6">Group Trip is designed to match you to your preferred vacation with people you would enjoy traveling with.</p>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
export default {
}
</script>
